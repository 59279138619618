































































































































































































































import { InputSetups } from '@/mixins/input-setups'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Guid } from 'guid-typescript'

// CONSTANTS
const PREFIX = 'scoring_system_'

@Component({
  'components': {
    ConfigField,
  },
})
export default class ScoringSystem extends Mixins(UseFields, InputSetups) {
  @Prop() readonly ranks!: Array<any>

  @Prop() readonly useXp!: boolean

  @Prop() readonly useReputation!: boolean

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  DIFF_VALUE = 150

  popupIsOpen = false

  rankModel = this.defaultRankModelValues()

  editKey: null | string = null

  defaultRankModelValues(): any {
    return {
      title: '',
      xp: this.xpMinValue + this.DIFF_VALUE,
      reputation: this.reputationMinValue + this.DIFF_VALUE,
    }
  }

  get lastRankItem(): any {
    return this.chatRanks.at(-1) ?? { xp: Number.MAX_SAFE_INTEGER, reputation: Number.MAX_SAFE_INTEGER }
  }

  get firstRank(): any {
    return this.chatRanks[1] ?? { xp: Number.MAX_SAFE_INTEGER, reputation: Number.MAX_SAFE_INTEGER }

  }

  get xpMinValue(): number {
    return this.lastRankItem.xp
  }

  get reputationMinValue(): number {
    return this.lastRankItem.reputation
  }

  get chatRanks(): Array<any> {
    return this.ranks.slice()
  }

  get newRankCreateAvailable(): boolean {
    return !!this.newRankRep && !this.newRankRep.invalid || !!this.newRankXp && !this.newRankXp.invalid
  }

  getRangeItems(index: number) {
    const high = this.chatRanks[index]
    const low = this.chatRanks[index - 1]

    return {
      high,
      low
    }
  }

  get valid() {
    return {
      invalid: false
    }
  }

  highestRankCheck(modelField: number, diffField: number) {
    if (modelField < diffField) {
      return {
        invalid: true,
        message: this.$t('rank_system_range_text', { left: diffField, right: Number.MAX_SAFE_INTEGER }).toString()
      }
    }

    return this.valid
  }

  rangeRankCheck(modelField: number, highDiff: number, lowDiff: number) {
    if (modelField > highDiff || modelField < lowDiff) {
      return {
        invalid: true,
        message: this.$t('rank_system_range_text', { left: lowDiff ?? 0, right: highDiff }).toString()
      }
    }

    return this.valid
  }

  get newRankRep() {
    if (this.useReputation && this.rankModel) {
      if (this.higherRepRankByInput === -1) {
        const lowerItem = this.lastRankItem

        return this.highestRankCheck(this.rankModel.xp, lowerItem.xp)
      }

      const { low, high } = this.getRangeItems(this.higherRepRankByInput)

      return this.rangeRankCheck(this.rankModel.xp, high.xp, low.xp)
    }
  }

  get newRankXp() {
    if (this.useXp && this.rankModel) {
      if (this.higherXpRankIndexByInput === -1) {
        const lowerItem = this.lastRankItem

        return this.highestRankCheck(this.rankModel.reputation, lowerItem.reputation)
      }

      const { low, high } = this.getRangeItems(this.higherXpRankIndexByInput)

      return this.rangeRankCheck(this.rankModel.reputation, high.reputation, low.reputation)
    }
  }

  get higherXpRankIndexByInput() {
    return this.chatRanks.findIndex(rank => rank.xp > this.rankModel.xp)
  }

  get higherRepRankByInput() {
    return this.chatRanks.findIndex(rank => rank.reputation > this.rankModel.reputation)
  }

  get editRankInvalid(): boolean {
    if (this.editKey) {
      const editItemIndex = this.chatRanks.findIndex(rank => rank.key === this.editKey)
      const higherItemXp = this.chatRanks[this.higherXpRankIndexByInput]
      const higherItemRep = this.chatRanks[this.higherRepRankByInput]

      const isXpInvalid = higherItemXp && (this.rankModel.reputation > higherItemXp.reputation)
      const isRepInvalid = higherItemRep && this.rankModel.xp > higherItemRep.xp

      if (editItemIndex === this.higherXpRankIndexByInput || editItemIndex === this.higherRepRankByInput) {
        return false
      }

      return isXpInvalid || isRepInvalid
    }

    return true
  }

  setRecordToEdit(rankRecord: any): void {
    this.rankModel = { ...rankRecord }

    this.editKey = rankRecord.key
  }

  updateRecord(): void {
    const itemIndex = this.chatRanks.findIndex(r => r.key === this.rankModel.key)
    this.chatRanks.splice(itemIndex, 1, this.rankModel)

    this.$emit('input', this.sortData(this.chatRanks))
    this.editKey = null
  }

  deleteRecord(recordKey: string): void {
    const itemIndex = this.chatRanks.findIndex(r => r.key === recordKey)
    this.chatRanks.splice(itemIndex, 1)

    this.$emit('input', this.sortData(this.chatRanks))
  }

  handleClose(): void {
    this.resetRankModel()
    this.editKey = null
  }

  resetRankModel(): void {
    this.rankModel = this.defaultRankModelValues()
  }

  addNewRank(): void {
    const localRanks = this.chatRanks
    localRanks.push({ ...this.rankModel, guid: Guid.create().toString() })

    this.$emit('input', this.sortData(localRanks))

    this.popupIsOpen = false
  }

  sortData(data: any): any {
    return data.slice()
      .sort((a: any, b: any) => {
        if (this.useXp && this.useReputation) {
          return (a.xp - b.xp) + (a.reputation - b.reputation)
        }
        if (this.useXp) {
          return a.xp - b.xp
        }
        if (this.useReputation) {
          return a.reputation - b.reputation
        }
      })
  }

}
