







































































































import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import EditorButtonsHelpView from "@/includes/helpViews/editorButtonsHelpView.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import ScoringSystem from "@/components/ScoringSystem.vue";
import RanksHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/RanksHandlerModule/RanksHandlerModuleBuilder";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import ModuleConfigSettings from "@/components/Modules/components/ModuleConfigSettings.vue";
import ModuleConfigSettingsWrapper from "@/components/Modules/components/ModuleConfigSettingsWrapper.vue";

import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue';
import ExtendedHelpMessage from "piramis-base-components/src/components/ExtendedHelpMessage.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import { MediaEditorMode } from "piramis-base-components/src/components/NewMessageEditors/types";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';
import { Guid } from "guid-typescript";

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    CenteredColumnLayout,
    PageTitle,
    ExtendedHelpMessage,
    NotAvailableOptionsOverlay,
    MultiMessageEditorWithMediaInput,
    SwitchInput,
    ScoringSystem,
    HighlightAnchor
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView
    }
  }
})
export default class RanksHandlerModuleView extends Mixins(InputSetups, TariffsTagsHelper, Placeholders, ModuleBindings) {
  @VModel() module!: RanksHandlerModuleBuilder

  get tariffTag() {
    return this.module.tariffTags()
  }

  get frontendRanks() {
    return this.module.model.params.user_ranks
      .map((rank, index, arr) => {
        if (index === 0) {
          return { key: Guid.create().toString(), xp: null, reputation: null, title: rank.title }
        } else {
          return {
            key: Guid.create().toString(),
            xp: arr[index - 1].xp,
            reputation: arr[index - 1].reputation,
            title: rank.title,
          }
        }
      })
  }

  configRanks(ranks: Array<any>) {
    return ranks.slice()
      .map((rank, index, arr) => {
        if (index === arr.length - 1) {
          return {
            xp: Number.MAX_SAFE_INTEGER,
            reputation: Number.MAX_SAFE_INTEGER,
            title: rank.title,
          }
        } else {
          return {
            xp: arr[index + 1].xp,
            reputation: arr[index + 1].reputation,
            title: rank.title,
          }
        }
      })
  }

  onRanksChange(ranks: Array<any>) {
    this.module.model.params.user_ranks = this.configRanks(ranks)
  }

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
